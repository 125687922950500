import { collection, getDocs } from 'firebase/firestore';
import { db } from './firebase';

const getCafes = async () => {
    const querySnapshot = await getDocs(collection(db, 'cafes'));
    const cafes = [];
    querySnapshot.forEach((doc) => {
      cafes.push({ id: doc.id, ...doc.data() });
    });
    return cafes;
  };
  
  export { getCafes };
  