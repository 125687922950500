import React, { useState } from 'react';
import './searchBar.css';

const SearchBar = ({ setFilteredCafes, cafes }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = () => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const filtered = cafes.filter(cafe => 
      cafe['Cafe Name'].toLowerCase().includes(lowerCaseSearchTerm) ||
      cafe['City'].toLowerCase().includes(lowerCaseSearchTerm)
    );
    setFilteredCafes(filtered);
  };

  return (
    <div className='search-bar'>
      <input 
        type="text" 
        value={searchTerm} 
        onChange={(e) => setSearchTerm(e.target.value)} 
        placeholder="Search" 
        className='search-input'
      />
     <button onClick={() => handleSearch(searchTerm)} className="search-button">
      <img src="/search-icon.png"  alt="Search" className='search-icon'/>
      </button>
    </div>
  );
};

export default SearchBar;
