import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './SignUp.css'; 

const SignUp = ({ signUp }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password.length < 6) {
      setError('Password must be at least 6 characters long');
      return;
    }
    setError('');
    signUp(email, password, name)
      .then((user) => {
        console.log('User signed up:', user);
        navigate('/');
      })
      .catch((error) => {
        console.error('Error during sign up:', error);
        setError(error.message);
      });
  };

  return (
    <div className="SignUp">
      <div className="back-home">
        <Link to="/">Back to Homepage</Link>
      </div>
      <h1>Sign Up</h1>
      <form className="signup-form" onSubmit={handleSubmit}>
        <label>
          <input type="text" name="name" value={name} onChange={(e) => setName(e.target.value)} required placeholder='Name'/>
        </label>
        <label>
          <input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required placeholder='Email'/>
        </label>
        <label>
          <input type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} required placeholder='Password'/>
        </label>
        {error && <p className="error">{error}</p>}
        <button type="submit">Sign Up</button>
      </form>
      <p>Already have an account? <Link to="/login">Login</Link></p>
    </div>
  );
};

export default SignUp;
