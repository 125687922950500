import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Profile.css';
import { auth, storage } from './firebase'; 
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { updateProfile } from 'firebase/auth';

const DEFAULT_PROFILE_PICTURE_URL = 'https://firebasestorage.googleapis.com/v0/b/peaberry-1fcbc.appspot.com/o/Defaultpfp.png?alt=media&token=361d4525-b5f5-465d-b356-a4ac18599095';

const Profile = () => {
    const [user, setUser] = useState(null);
    const [image, setImage] = useState(null);
    const [error, setError] = useState('');
    const [profileImageUrl, setProfileImageUrl] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
          if (user) {
            setUser(user);
            setProfileImageUrl(user.photoURL || DEFAULT_PROFILE_PICTURE_URL);
          } else {
            navigate('/login');
          }
        });
    
        return () => unsubscribe();
      }, [navigate]);
    
      const handleImageChange = (e) => {
        if (e.target.files[0]) {
          setImage(e.target.files[0]);
        }
      };
    
      const handleImageUpload = () => {
        if (image) {
          const storageRef = ref(storage, `profilePictures/${user.uid}`);
          uploadBytes(storageRef, image).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
              updateProfile(user, { photoURL: url }).then(() => {
                setProfileImageUrl(url);
                setImage(null);
                setError('');
              }).catch((error) => {
                setError('Error updating profile picture');
              });
            });
          }).catch((error) => {
            setError('Error uploading image');
          });
        } else {
          setError('Please select an image to upload');
        }
      };

      const handleDeleteProfilePicture = () => {
        if (user && user.photoURL !== DEFAULT_PROFILE_PICTURE_URL) {
            const storageRef = ref(storage, `profilePictures/${user.uid}`);
            
            deleteObject(storageRef)
              .then(() => {
                return updateProfile(auth.currentUser, { photoURL: DEFAULT_PROFILE_PICTURE_URL });
              })
              .then(() => {
                setError('');
              })
              .catch((error) => {
                setError('Error deleting profile picture');
              });
          } else {
            setError('No profile picture to delete');
          }
        };

      return (
        <div className="Profile">
          <div className="back-home">
            <Link to="/">Back to Homepage</Link>
          </div>
          <h1>Account Information</h1>
          <div className="profile-info">
            <div className="profile-item">
              <label>Name:</label>
              <span>{user ? user.displayName : ''}</span>
            </div>
            <div className="profile-item">
              <label>Email:</label>
              <span>{user ? user.email : ''}</span>
            </div>
            <div className="profile-item">
              <label>Password:</label>
              <span className="password">••••••••</span>
            </div>
            <div className="profile-item">
              <label>Profile Picture:</label>
              <img src={profileImageUrl} alt="     " className="profile-picture" />
            </div>
            <div className="profile-item">
              <input type="file" onChange={handleImageChange} />
              <button onClick={handleImageUpload}>Upload</button>
              <button onClick={handleDeleteProfilePicture}>Delete</button>
            </div>
            {error && <p className="error">{error}</p>}
          </div>
        </div>
      );
    };

export default Profile;
