import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getCafes } from './firestoreService';
import SearchBar from './searchBar';
import { storage } from './firebase'; 
import { ref } from 'firebase/storage';
import './Home.css';

const DEFAULT_PROFILE_PICTURE_URL = 'https://firebasestorage.googleapis.com/v0/b/peaberry-1fcbc.appspot.com/o/Defaultpfp.png?alt=media&token=361d4525-b5f5-465d-b356-a4ac18599095';

const Home = ({ user, logOut }) => {
  const [cafes, setCafes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredCafes, setFilteredCafes] = useState([]);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [profilePicture, setProfilePicture] = useState(DEFAULT_PROFILE_PICTURE_URL); 
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [activeFilterDropdown, setActiveFilterDropdown] = useState({
    city: false,
    roast: false,
    brewing: false,
    roaster: false,
  });
  
  const [selectedFilters, setSelectedFilters] = useState({
    city: [],
    roast: [],
    brewing: [],
    roaster: [],
  }); 

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentCafes = filteredCafes.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(filteredCafes.length / itemsPerPage);

  useEffect(() => {
    const fetchCafes = async () => {
      try {
        const data = await getCafes();
        setCafes(data);
        setFilteredCafes(data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    const fetchProfilePicture = async (uid) => {
      try {
        const storageRef = ref(storage, `profilePictures/${user.uid}`);
        const url = await storageRef.getDownloadURL();
        setProfilePicture(url);
      } catch (error) {
        console.error("Error fetching profile picture:", error);
        setProfilePicture(DEFAULT_PROFILE_PICTURE_URL);
      }
    };
    if (user) {
      fetchProfilePicture(user.uid);
      setProfilePicture(user.photoURL || DEFAULT_PROFILE_PICTURE_URL);
    } else {
      setProfilePicture(DEFAULT_PROFILE_PICTURE_URL);
    }

    fetchCafes();

  }, [user]);

  useEffect(() => {
    const filterCafes = () => {
      let filtered = cafes;

      if (selectedFilters.city.length > 0) {
        filtered = filtered.filter(cafe => selectedFilters.city.includes(cafe['City']));
      }

      if (selectedFilters.roast.length > 0) {
        filtered = filtered.filter(cafe => selectedFilters.roast.includes(cafe['Roast Level']));
      }

      if (selectedFilters.brewing.length > 0) {
        filtered = filtered.filter(cafe => {
          const methods = cafe['Brewing Method'] ? cafe['Brewing Method'].split(', ').map(method => method.toLowerCase()) : [];
          return selectedFilters.brewing.some(brewing => methods.includes(brewing.toLowerCase()));
        });
      }

      if (selectedFilters.roaster.length > 0) {
        filtered = filtered.filter(cafe => selectedFilters.roaster.includes(cafe['Roaster']));
      }

      setFilteredCafes(filtered);
      setCurrentPage(1);
    };

    filterCafes();
  }, [selectedFilters, cafes]);

  const sortCafes = (key) => {
    const sorted = [...filteredCafes].sort((a, b) => {
      if (a[key].toLowerCase() < b[key].toLowerCase()) return -1;
      if (a[key].toLowerCase() > b[key].toLowerCase()) return 1;
      return 0;
    });
    setFilteredCafes(sorted);
  };

  const resetFilters = () => {
    setSelectedFilters({
      city: [],
      roast: [],
      brewing: [],
      roaster: []
    });
    setFilteredCafes(cafes);
    setCurrentPage(1);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(prevState => !prevState);
    console.log("Dropdown is now:", !isDropdownOpen ? "Open" : "Closed");
  };

  const toggleFilterDropdown = (filter) => {
    setActiveFilterDropdown(prevState => ({
      ...prevState,
      [filter]: !prevState[filter]
    }));
  };

  const handleFilterSelection = (filterType, filterValue) => {
    setSelectedFilters(prevState => {
      const currentSelections = prevState[filterType];
      if (currentSelections.includes(filterValue)) {
        return {
          ...prevState,
          [filterType]: currentSelections.filter(selection => selection !== filterValue)
        };
      }else {
        return {
          ...prevState,
          [filterType]: [...currentSelections, filterValue]
        };
      }
    });
  };

  const isSelected = (filterType, filterValue) => {
    return selectedFilters[filterType].includes(filterValue);
  };

  return (
    <div className="whole-page">
      <div className="Home">
        <div className="title-section">
          <h1>Peaberry</h1>
              <div className="search-section">
                <SearchBar setFilteredCafes={setFilteredCafes} cafes={cafes} />
              </div>
              <div className="dropdown">
                <button className="dropbtn" onClick={toggleDropdown}>
                  <img src={profilePicture} alt="     " />
                </button>
                {isDropdownOpen && (
                <div className="dropdown-content">
                  {user && user.displayName && (
                <div className="profile-name">{user.displayName}</div>
                )}
                  <nav> 
                    {!user && <Link to="/signup">Sign Up</Link>} 
                    {!user && <Link to="/login">Login</Link>}
                    {user ? <Link to="/profile">Profile</Link> : null}
                    {user && <button onClick={logOut}>Log Out</button>}
                  </nav> 
                </div>
                )}
              </div>
              <div className="user-section">
                {user ? <p>Welcome, {user.displayName}</p> : <p>Please log in or sign up.</p>}
              </div>
            </div>
        <div className="top-border">
          </div>
            <div className="column side">
              <div className="filter-dropdowns">
              <button className="reset-button" onClick={resetFilters}>
                Clear all
            </button>
            <div className="filter-dropdown">
              <button onClick={() => toggleFilterDropdown('city')}>
                City
              </button>
                <div className={`filter-dropdown-menu ${activeFilterDropdown.city ? 'show' : ''}`}>
                  {['Acton', 'Boston', 'Cambridge', 'Ipswich', 'Newtonville', 'Sudbury', 'Watertown'].map(city => (
                    <button key={city} onClick={() => handleFilterSelection('city', city)}>
                      <div className={`checkbox ${isSelected('city', city) ? 'checked' : ''}`}></div>
                      {city}
                  </button>
                ))}
                </div>
             </div>
            <div className="filter-dropdown">
              <button onClick={() => toggleFilterDropdown('roast')}>
                Roast Level
              </button>
              <div className={`filter-dropdown-menu ${activeFilterDropdown.roast ? 'show' : ''}`}>
                  {['Various', 'Light', 'Light-Medium'].map(roastLevel => (
                    <button key={roastLevel} onClick={() => handleFilterSelection('roast', roastLevel)}> 
                      <div className={`checkbox ${isSelected('roast', roastLevel) ? 'checked' : ''}`}></div>
                      {roastLevel}
                  </button>
                ))}
                </div>
              </div>
            <div className="filter-dropdown">
              <button onClick={() => toggleFilterDropdown('brewing')}>
                Brewing Method
              </button>
                <div className={`filter-dropdown-menu ${activeFilterDropdown.brewing ? 'show' : ''}`}>
                  {['Pour over', 'Drip Coffee', 'Espresso-based'].map(brewingMethod => (
                    <button key={brewingMethod} onClick={() => handleFilterSelection('brewing', brewingMethod)}>
                      <div className={`checkbox ${isSelected('brewing', brewingMethod) ? 'checked' : ''}`}></div>
                      {brewingMethod}
                  </button>
                  ))}
                </div>
              </div>
            <div className="filter-dropdown">
              <button onClick={() => toggleFilterDropdown('roaster')}>
                Roaster
              </button>
                <div className={`filter-dropdown-menu ${activeFilterDropdown.roaster ? 'show' : ''}`}>
                  {['Yes', 'No'].map(roaster => (
                    <button key={roaster} onClick={() => handleFilterSelection('roaster', roaster)}>
                      <div className={`checkbox ${isSelected('roaster', roaster) ? 'checked' : ''}`}></div>
                      {roaster}
                  </button>
                  ))}
                </div>
              </div>
          </div>
        </div>
          <div className="bottom-section">
            <div className='row'>
            <div className="cafe-title">
              <button onClick={() => sortCafes('Cafe Name')}>Sort by Cafe Name</button>
              <button onClick={() => sortCafes('City')}>Sort by Cafe Location</button>
            </div>
          <div className="cafe-list-section">
            {loading && <p>Loading...</p>}
            {error && <p className="error">{error}</p>}
            {!loading && !error && (
              <>
              <table>
                <thead>
                  <tr>
                    <th className='city'>City</th>
                    <th className='cafe-name'>Cafe Name</th>
                    <th className='operational-details'>Operational Details</th>
                    <th className='roast-level'>Roast Level</th>
                    <th className='brewing-method'>Brewing Method</th>
                    <th className='roaster'>Roaster</th>
                    <th className='address'>Address</th>
                  </tr>
                </thead>
                <tbody>
                  {currentCafes.map((cafe) => (
                    <tr key={cafe.id}>
                      <td>{cafe['City']}</td>
                      <td>{cafe['Cafe Name']}</td>
                      <td>
                      {cafe['Operational Details (Opening Hours, Days opened)']
                        .split(', ')
                        .map((detail, index) => (
                          <div key={index}>{detail}</div>
                        ))}
                      </td>
                      <td>{cafe['Roast Level']}</td>
                      <td>{cafe['Brewing Method']}</td>
                      <td>{cafe['Roaster']}</td>
                      <td>
                        {cafe['Address'] ? (
                        <a href={cafe['Address']} target="_blank" rel="noopener noreferrer">
                          View on Google Maps
                        </a>
                        ) : (
                          'No link available'
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              </> 
              )}
           </div>
             <div className="pagination">
               {[...Array(totalPages)].map((_, index) => (
                 <button
                   key={index + 1}
                   onClick={() => paginate(index + 1)}
                  className={index + 1 === currentPage ? 'active' : ''}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
