import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import './Login.css';

const Login = ({ logIn }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    logIn(email, password)
    .then((user) => {
      console.log('User logged in:', user);
      navigate('/');
    })
    .catch((error) => {
      if (error.code === 'auth/invalid-credential') {
        console.error('Password is incorrect.');
         setErrorMessage('The password you entered is incorrect. Please try again.');
      } else {
        console.error('Error during login:', error.message);
      }
    });
  };

  return (
    <div className="Login">
      <h1>Login</h1>
          <form className="login-form" onSubmit={handleSubmit}>
        <label>
          <input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email"/>
        </label>
        <label>
          <input type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder='Password' />
        </label>
       <button type="submit">Login</button>
    </form>
    {errorMessage && (
        <div className="error-message">
          {errorMessage}
        </div>
      )}
     <p>Don't have an account? <Link to="/signup">Sign Up</Link></p> 
     <div className="back-home">
       <Link to="/">Back to Homepage</Link>
     </div>
   </div>   
  );
};

export default Login;
